import { URLS } from "../constants/config"

class ExamService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  async deleteExam(examUuid) {
    try {
      const url = URLS.INTERNAL.DELETE_EXAM;
      const body = { examUuid: examUuid };
      await this.httpClient.delete(url, body);
      return {};
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async getExam(schoolUUID = null) {
    try {
      const url = URLS.INTERNAL.GET_EXAM;
      const body = { schoolUuid: schoolUUID };
      return await this.httpClient.post(url, body);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async getRecordings(url) {
    try {
      return await this.httpClient.get(url);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async createExam(student) {
    try {
      const url = URLS.INTERNAL.POST_EXAM;
      const body = { studentUuid: student?.userUuid };
      return await this.httpClient.post(url, body);
    } catch (error) {
      console.error(error);
    }
  }
  async getTeacherListing() {
    try {
      let url = URLS.INTERNAL.TEACHERS_LISTING;
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getTeachersAndStudents(schoolUUID) {
    return {
      teachers: await this.getListOfTeachers(schoolUUID),
      students: await this.getListOfStudents(schoolUUID),
    };
  }

  async getListOfTeachers(schoolUUID) {
    let payload = [];
    try {
      let url = URLS.INTERNAL.LIST_OF_TEACHERS(schoolUUID);
      const response = await this.httpClient.get(url);
      if (response.ok) payload = response.json();
      return payload;
    } catch (error) {
      console.log(error);
      return payload;
    }
  }

  async getListOfTeacherStudentAssignment(teacherUuid) {
    let payload = [];
    try {
      let url = URLS.INTERNAL.ASSSIGNMENT.GET(teacherUuid);
      const response = await this.httpClient.get(url);
      if (response.ok) {
        const data = await response.json();
        payload = data.response;
      }
      return payload;
    } catch (error) {
      console.log(error);
      return payload;
    }
  }
  async SaveTeacherStudentAssignment(assignment) {
    let payload = { success: false, message: null };
    try {
      const urlAdd = URLS.INTERNAL.ASSSIGNMENT.ADD;
      const urlRemove = URLS.INTERNAL.ASSSIGNMENT.REMOVE;
      let responseAdd = { ok: false };
      let responseRemove = { ok: false };
      if (assignment.add.studentUuid.length > 0) {
        responseAdd = await this.httpClient.post(urlAdd, assignment.add);
      } else {
        responseAdd.ok = true;
      }
      if (assignment.remove.studentUuid.length > 0) {
        responseRemove = await this.httpClient.post(
          urlRemove,
          assignment.remove
        );
      } else {
        responseRemove.ok = true;
      }
      if (responseAdd.ok && responseRemove.ok) payload.success = true;

      return payload;
    } catch (error) {
      payload.message = error;
      console.log(error);
      return payload;
    }
  }

  async generateDataset() {
    try {
      let url = URLS.INTERNAL.GENERATE_DATASET;
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getListOfSchools() {
    try {
      let url = URLS.INTERNAL.SCHOOLS_LISTING;
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getListOfStudents(schoolUUID) {
    let payload = [];
    try {
      const url = URLS.INTERNAL.LIST_OF_STUDENTS(schoolUUID);
      const response = await this.httpClient.get(url);
      if (response.ok) payload = await response.json();
      return payload;
    } catch (error) {
      console.log(error);
      return payload;
    }
  }
  async getScores(examUuid) {
    try {
      let url = URLS.INTERNAL.SCORES_LISTING;
      const response = await this.httpClient.post(url, { examUuid: examUuid });
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getDatasetStat(examUuid) {
    try {
      let url = URLS.INTERNAL.GET_DATASET_STAT_FOR_SCORING(examUuid);
      const response = await this.httpClient.get(url);
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getAudioForScore(datasetAudioUuid) {
    try {
      let url = URLS.INTERNAL.GET_AUDIO_FOR_SCORING(datasetAudioUuid);
      const response = await this.httpClient.get(url);
      return response.blob();
    } catch (error) {
      console.error(error);
    }
  }

  async computeAIScore(examAudioUuid) {
    try {
      let url = URLS.INTERNAL.COMPUTE_AI_SCORE(examAudioUuid);
      const response = await this.httpClient.get(url);
      if (response.ok) return true;
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  async updateScore(payload) {
    try {
      let url = URLS.INTERNAL.SCORE_UPDATE;
      const response = await this.httpClient.post(url, {
        datasetAudioUuid: payload.datasetAudioUuid,
        humanScore: payload.score,
        transcription: payload.transcription,
        isClear: payload.isClear,
      });
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async updateTranscription(examAudioUuid) {
    try {
      let url = URLS.INTERNAL.TRANSCRIPTION_UPDATE(examAudioUuid);
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
export default ExamService