import { useEffect, useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import { Services } from "../services";
import { Link, useParams, useSearchParams } from "react-router-dom";

import ScoreSummary from "../components/Score/Summary";
import ScoreList from "../components/Score/List";
import ScoreStudentInfo from "../components/Score/StudentInfo";

import { ArrowLeft } from 'react-bootstrap-icons'
import { SectionLists } from "../constants/config";
import Assessment from "../components/Score/Assessment";

const Scores = () => {
  const [list, setList] = useState([])
  const [sections,setSections] = useState([])
  const [summary, setSummary] = useState([])
  const [student, setStudent] = useState()
  const [assessment, setAssessment] = useState()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const setScores = async () => {    
    setSummary(await Services.Exam.getDatasetStat(params?.id))
    let data = await Services.Exam.getScores(params?.id)
    data = data.map((item) => {
      return {
        ...item
        ,score: item?.score ? parseFloat(item?.score) : item.score,
      };
    });

    const o = Object.groupBy(data,s=>{
      return s.sectionId
    })

    // let o = data.reduce((acc, person) => {
    //   const age = person.sectionId;
    //   if (!acc[age]) {
    //     acc[age] = [];
    //   }
    //   acc[age].push(person);
    //   return acc;
    // }, {});

    setSections(Object.keys(o).map((item) => {
      return SectionLists.find((x) => { return x.id === parseInt(item) })
    }).sort((a, b) => {
      return a.order - b.order
    }))
    setList(data)
  }
  const setStudentInfo = async (id) => {
    const data = await Services.User.showStudentInfo(id)
    setStudent(data)
  }

  const setAssessmentInfo = async (id) => {
    const data = await Services.User.showAssessmentInfo(id)
    setAssessment(data?.payload);
  }
  const [egraS3Json, setEgraS3Json] = useState()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAssessment, setShowAssessment] = useState(false);
  const handleCloseAssessment = () => setShowAssessment(false);
  const handleShowAssessment = () => setShowAssessment(true);

  useEffect(() => {
    if (list.length === 0) {
      setScores().then(() => {
        Services.Exam.getExam(searchParams.get('schoolUuid')).then(async (data) => {
          const result = await data.json()
          setEgraS3Json(result)
        })
      })
    }
  },[])

  return (
    <>
      <NavBar />
      <div style={{ textAlign: "center", paddingBottom: "1.5em" }} >
        <div style={{float:"left",fontSize:"2em"}}>
        <Link style={{ color: "white" }} to={"/list-recordings"}>
          <ArrowLeft style={{ color: "white" }} />
        </Link>
        </div>
        <h2 style={{color:"white", fontSize: "2em"}}>
        {list.length>0?list[0].studentName:""}
        </h2>
        <h3 style={{color:"white", fontSize: "2em"}}>{list.length>0?list[0].schoolName:""}</h3>
      </div>
      <ScoreStudentInfo
        show={show}
        handleClose={handleClose}
        student={student}
      />
      <Assessment
        showAssessment={showAssessment}
        handleCloseAssessment={handleCloseAssessment}
        assessment={assessment}
      />
      <ScoreSummary summary={summary} />
      <ScoreList
        list={list}
        setStudentInfo={setStudentInfo}
        setAssessmentInfo={setAssessmentInfo}
        handleShow={handleShow}
        handleShowAssessment={handleShowAssessment}
        setList={setList}
        setScores={setScores}
        egraS3Json={egraS3Json}
        sections={sections}
      />
    </>
  );
}

export default Scores;