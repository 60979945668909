
import React,{useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import styles from './List.module.css';
import { SectionUtility } from '../../utils/tools';
import { Services } from '../../services';
import Toast from 'react-bootstrap/Toast';
import HTMLReactParser from 'html-react-parser';
import { URLS } from '../../constants/config';

const AIScoreSupportedSections = [1, 6];
const formatDate = (date) => {
  const d = new Date(date)
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  return d.toLocaleTimeString('en-us', options)
}

const ScoreList = ({
  list,
  setStudentInfo,
  setAssessmentInfo,
  handleShow,
  handleShowAssessment,
  setList,
  setScores,
  egraS3Json,
  sections

}) => {
const [btnTranscriptionState, setBtnTranscriptionState] = useState([])
//question is index+1 value from egra json
const getSectionLabel = (id, question) => {
  
  let details = SectionUtility.GetSectionDetails(id)
  let label = details?.display
  
  if (egraS3Json) {
    let egraSection = SectionUtility.GetEgra3Section(egraS3Json, id)

    if (SectionUtility.IsSectionListeningType(details) === true) {
      label = label + " [" + (egraSection.answers.length>0?egraSection.answers[question-1]:"") + "]"
      label = egraSection.answers.length>0?egraSection.answers[question-1]:""
      if(id===7)
        label=(egraSection.answers.length>0?egraSection.questions_text[question-1]:"")+`<br>`+label
    }
    else {
      label = label + " [" + egraSection.questions[question-1] + "]"
      label =egraSection.questions[question-1]
    }
    return label
  }
  return ""
}
useEffect(()=>{
  setBtnTranscriptionState(sections.map((section)=>{
    return {...section,loading:false}
  }))
},[sections])
const [showToast, setShowToast] = useState(false)
const [assessmentEnabled, setAssessmentEnabled] = React.useState(true);

const Toaster = ()=>{
  return <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
  <Toast
    className="d-inline-block m-1"
    bg={"success"}
    show={showToast}
    delay={3000} autohide={true}
  >
    <Toast.Body style={{ color: "white" }}>
      Score saved.
    </Toast.Body>
  </Toast>
</div>
}

const SectionItems = ({section})=>{
  const recordings = [...list].filter((x)=>{ return x.sectionId===section.id})
  const SaveSectionScoreButton = ({label}) => {
    return (
      <button
        className="btn btn-primary"        
        onClick={(e) => {
          const audioUUID = [];
          const scores = [];
          const transcriptions = [];
          const isclears = [];
          recordings.forEach(
            (
              {
                sectionId,
                userUuid,
                createdAt,
                datasetAudioUuid,
                studentName,
                schoolName,
                question,
                score,
                isClear,
                transcription,
              },
              index
            ) => {
              const elScore = document.getElementById(
                "score-" + datasetAudioUuid + "-" + index
              ).value;
              const elTranscription = document.getElementById(
                "transcription-" + datasetAudioUuid + "-" + index
              ).value;
              const elIsClear = document.getElementById(
                "isclear-" + datasetAudioUuid + "-" + index
              ).value;
              audioUUID.push(datasetAudioUuid);
              scores.push(elScore);
              transcriptions.push(elTranscription);
              isclears.push(elIsClear);
            }
          );

          Services.Exam.updateScore({
            datasetAudioUuid: audioUUID,
            score: scores,
            transcription: transcriptions,
            isClear: isclears,
          }).then((response) => {
            window.location.reload();
            console.log(response);
          });
        }}
      >
        {label?label:'Save All'}
      </button>
    );
  };
  const handleSaveRowClick = (e)=>{
    const key = e.currentTarget.getAttribute('data-item')
    const audioUUID =e.currentTarget.getAttribute('data-audio-uuid')
    const elemScore = document.getElementById('score-'+audioUUID+"-"+key)
    const elemIsClear = document.getElementById('isclear-'+audioUUID+"-"+key)
    const elemTranscription = document.getElementById('transcription-'+audioUUID+"-"+key)

    const score =elemScore.value
    const transcription =elemTranscription.value      
    const isClear = elemIsClear.value
    Services.Exam.updateScore({
      datasetAudioUuid: [audioUUID],
      score: [score],
      transcription: [transcription],
      isClear:[isClear]
    })
      .then((response) => {
        const highlight = (el)=>{
          const color = "#dee2e6"
          const width = "1px"
          el.style.background = "#DAF7A6"
          el.style.borderWidth = "3px"
          setTimeout(() => {
            el.style.borderColor = color
            el.style.borderWidth = width
            el.style.background = "unset"
          }, 500);
        }
        highlight(elemScore)
        highlight(elemTranscription)
      })
      .catch((e) => {
        console.log("updateScore", e);
      });
  }

  const SectionHeading = () => {
    const label = `${
      section?.value === "Onset Sound" ? "Phonemic Awareness" : section?.value
    } ${
      recordings[0] ? " [" + formatDate(recordings[0]?.createdAt) + "]" : ""
    }`;
    return (
      <div
        style={{
          float: "left",
          color: "white",
          marginRight: "2em",
          fontSize: "1.5em",
        }}
      >
        {label}
      </div>
    );
  };

  const AnalyzeButton = ({label}) => {
    const supportedSections = [1, 6, 7, 5];
    if (!supportedSections.includes(section.id)) return "";
    return (
      <button
        disabled={
          btnTranscriptionState.find((x) => {
            return x.id === section.id;
          })?.loading === true
            ? true
            : false
        }
        className="btn btn-primary"
       
        onClick={(e) => {
          const examAudioUuids = recordings.map((item) => {
            return item.examAudioUuid;
          });
          setBtnTranscriptionState(
            btnTranscriptionState.map((x) => {
              if (x.id === section.id) x.loading = true;
              return x;
            })
          );
          if (examAudioUuids.length > 0) {
            Services.Exam.updateTranscription(examAudioUuids[0]).then(
              (response) => {
                window.location.reload();
              }
            );
          }
        }}
      >
        {label}
      </button>
    );
  };

  const ComputeAIScoreButton = ({label}) => {    
    if (!AIScoreSupportedSections.includes(section.id)) return "";
    return (
      <button
        className="btn btn-primary"
        onClick={(e) => {
          const examAudioUuids = recordings.map((item) => {
            return item.examAudioUuid;
          });
          if (examAudioUuids.length > 0) {
            Services.Exam.computeAIScore(examAudioUuids[0]).then((response) => {
              window.location.reload();
            });
          }
        }}
      >
        {label}
      </button>
    );
  };

  const ListeningSectionNarration = () => {
    return (
      <>
        <div
          style={{
            textAlign: "left",
            color: "white",
            marginBottom: "2em",
            marginTop: "3em",
          }}
        >
          <p>Narration:</p>
          <p>
            It is Sports Day at school. Anna is hiding under her desk. She is
            afraid she can’t run fast. Finally, Anna runs in a race, and she
            wins! Anna is happy.
          </p>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={styles.HeaderWrapper}>
        <div className="row">
          <div className="col-lg-7 col-12">
            <SectionHeading />
            {section.id===1?<ListeningSectionNarration />:''}            
          </div>
          <div className="col-12 col-lg-5">
            <div className={styles.HeaderButtons}>
            <SaveSectionScoreButton />
            <AnalyzeButton label={"Analyze"} />
            <ComputeAIScoreButton label={"AI Score"} />
            </div>
          </div>
        </div>
      </div>
      <table className="table table-bordered border-primary table-sm">
        <thead>
          <tr>
            <th style={{ width: "1em" }}>SN</th>
            <th style={{ width: "10em" }}>
              {section.id === 7 ? `Question/Correct answer` : `Display Text`}
            </th>
            <th style={{ width: "4em" }}>Score</th>
            {AIScoreSupportedSections.includes(section.id) && <th style={{ width: "3em" }}>AI Score</th>}
            {AIScoreSupportedSections.includes(section.id) && <th style={{ width: "10em" }}>AI Reason</th>}
            <th style={{ width: "4em" }}>Audio</th>
            <th style={{ width: "10em" }}>Transcription</th>
            <th style={{ width: "3em" }}>Audio OK?</th>
            <th style={{ width: "3em" }}></th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {recordings.map(
            (
              {
                sectionId,
                userUuid,
                createdAt,
                datasetAudioUuid,
                studentName,
                schoolName,
                question,
                score,
                transcription,
                isClear,
                audioUrl,
                aiScoreReason,
                aiScore,
              },
              index
            ) => {
              return (
                <tr key={"row-" + index.toString()}>
                  <td>{index + 1}</td>
                  {/* <td>{formatDate(createdAt)}</td> */}
                  {/* <td>
                    <Link
                      onClick={() => {
                        setStudentInfo(userUuid).then(() => {
                          handleShow();
                        });
                      }}
                    >
                      {studentName}
                    </Link>
                  </td>
                  <td>{schoolName}</td> */}
                  <td>
                    {sectionId && question
                      ? HTMLReactParser(getSectionLabel(sectionId, question))
                      : ""}
                  </td>
                  <td>
                    <input
                      id={"score-" + datasetAudioUuid + "-" + index}
                      data-item={index}
                      type="text"
                      className="form-control"
                      defaultValue={score}
                      style={{ height: "40px" }}
                      // onChange={(e) => {
                      //   setList(list.map((item) => {
                      //     if (datasetAudioUuid === item.datasetAudioUuid)
                      //       item.score = e.currentTarget.value
                      //     return item
                      //   }))
                      // }}
                    />
                  </td>
                  {AIScoreSupportedSections.includes(sectionId) && <td>{aiScore?parseInt(aiScore):''}</td>}
                  {AIScoreSupportedSections.includes(sectionId) && <td style={{textAlign:'left'}}>{aiScoreReason}</td>}
                  <td>
                    <audio
                      controls
                      style={{
                        height: "1.5em",
                        width: "10em",
                        border: "1px solid white",
                        borderRadius: "50px",
                      }}
                      src={`${URLS.baseuri}/api/audio-play/${audioUrl}`}
                      id={"audio-" + sectionId + index.toString()}
                      // style={{ display: "none" }}
                    >
                      Your browser does not support the audio tag.
                    </audio>
                    {sectionId === 5 && (
                      <Link
                        style={{
                          pointerEvents: assessmentEnabled ? "auto" : "none",
                          color: assessmentEnabled ? "" : "gray",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          setAssessmentEnabled(false);
                          setAssessmentInfo(datasetAudioUuid).then(() => {
                            setAssessmentEnabled(true);
                            handleShowAssessment();
                          });
                        }}
                      >
                        Assessment
                      </Link>
                    )}
                  </td>
                  <td>
                    {sectionId === 5 ? (
                      <>
                        <textarea
                          id={"transcription-" + datasetAudioUuid + "-" + index}
                          data-item={index}
                          className="form-control"
                          rows="10"
                          defaultValue={transcription.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )}
                          //onChange={handleChange}
                          // onChange={(e) => {
                          //   setList(list.map((item) => {
                          //     if (datasetAudioUuid === item.datasetAudioUuid)
                          //       item.transcription = e.currentTarget.value
                          //     return item
                          //   }))
                          // }}
                        ></textarea>
                      </>
                    ) : (
                      ""
                    )}
                    {sectionId !== 5 ? (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          style={{ height: "40px" }}
                          id={"transcription-" + datasetAudioUuid + "-" + index}
                          data-item={index}
                          defaultValue={transcription}
                          // onChange={(e) => {
                          //   setList(list.map((item) => {
                          //     if (datasetAudioUuid === item.datasetAudioUuid)
                          //       item.transcription = e.currentTarget.value
                          //     return item
                          //   }))
                          // }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <select
                      // disabled={Services.User.isTeacher() ? true : false}
                      className="form-control-sm"
                      id={"isclear-" + datasetAudioUuid + "-" + index}
                      defaultValue={"yes" === isClear ? "yes" : "no"}
                    >
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                  </td>
                  <td>
                    <button
                      //disabled={Services.User.isTeacher() ? true : false}
                      className="btn btn-sm btn-outline-primary"
                      defaultValue={datasetAudioUuid}
                      data-audio-uuid={datasetAudioUuid}
                      data-item={index}
                      onClick={handleSaveRowClick}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      {[2, 3, 4].includes(section.id) && (
        <div style={{ paddingBottom: "6em" }}>
          <SaveSectionScoreButton label={"Save Above Section"} />
        </div>
      )}
    </div>
  );
}

return(
  <>
    <Toaster />
    <h2 style={{ textAlign: "left", color: "white" }}>Breakdown</h2>
    {
      sections.map((section,index)=>{
        return <SectionItems section={section} key={index}/>
      })
    }
    </>
);
}
ScoreList.propTypes = {};

ScoreList.defaultProps = {};

export default ScoreList;
