export const Environments = {
  DEVELOPMENT: "DEVELOPMENT",
  PRODUCTION: "PRODUCTION",
  STAGING: "STAGING",
  isDev: process.env.REACT_APP_ENV === "DEVELOPMENT"
}
const HOSTS = {
  development:{value:"DEVELOPMENT",url:"http://localhost:8080"},
  production:{value:"PRODUCTION",url:"https://realreading.org"},
  production_africa: { value: "PRODUCTION_AFRICA", url: "https://africa.realreading.org" },
  production_usa: { value: "PRODUCTION_USA", url: "https://realreading.org" },
  production_test: { value: "PRODUCTION_TEST", url: "https://testusareal.realreading.org" },
  staging:{value:"STAGING",url:"https://realreading.org"}
}

export const LOGIN_TYPE_URLS = {
  COMPETITION:"/",
  //squid, and userpass login
  REGULAR:"/logintool",
}


export const audioSensitivenessControldB = {
  decible : -80
}

let HOST = HOSTS.development.url
if (HOSTS.development.value === process.env.REACT_APP_ENV)
  HOST = HOSTS.development.url
if (HOSTS.staging.value === process.env.REACT_APP_ENV)
  HOST = HOSTS.staging.url
if (HOSTS.production.value === process.env.REACT_APP_ENV)
  HOST = HOSTS.production.url
if (HOSTS.production_africa.value === process.env.REACT_APP_ENV)
  HOST = HOSTS.production_africa.url
if (HOSTS.production_usa.value === process.env.REACT_APP_ENV)
  HOST = HOSTS.production_usa.url
if (HOSTS.production_test.value === process.env.REACT_APP_ENV)
  HOST = HOSTS.production_test.url



export const URLS = {
  baseuri: HOST,
  section: `${HOST}/json/egra-content-active.json`,
  INTERNAL: {
    REGISTER: `${HOST}/api/auth/signup`,
    LOGIN: `${HOST}/api/auth/signin`,
    CHANGE_PASSWORD: `${HOST}/api/auth/change-password`,
    SQUID_LOGIN: `${HOST}/api/squid/signin`,
    MERGE_AUDIO: `${HOST}/api/upload-audio`,
    TEACHERS_LISTING: `${HOST}/get-exam-submissions-list`,
    BULK_REGISTER: `${HOST}/api/auth/bulk-signup`,
    PIN_RESET: `${HOST}/api/auth/pin-reset`,
    PASSWORD_RESET: `${HOST}/api/auth/password-reset`,
    POST_NEW_RESETTED_PASSWORD: `${HOST}/api/auth/password-reset-form`,
    CHECK_PASSWORD_RESET_TOKEN: (resetToken, email) =>
      `${HOST}/api/auth/password-reset/${resetToken}/${email}`,
    //Competition Logins
    COMPETITION_TEACHER_LOGIN: `${HOST}/api/auth/signin/teacher`,
    COMPETITION_STUDENT_LOGIN: `${HOST}/api/auth/signin/student`,

    GENERATE_DATASET: `${HOST}/api/dataset-section-generate`,

    SCORES_LISTING: `${HOST}/api/list-dataset`,
    STUDENT_INFO: (id) => `${HOST}/api/user/${id}`,
    ASSESSMENT_INFO: (datasetAudioUuid) =>
      `${HOST}/api/assessment/${datasetAudioUuid}`,
    TRANSCRIPTION_UPDATE: (examAudioUuid) =>
      `${HOST}/api/transcription/${examAudioUuid}`,
    SCORE_UPDATE: `${HOST}/api/update-dataset`,
    GET_AUDIO_FOR_SCORING: (datasetAudioUuid) =>
      `${HOST}/api/load-audio/${datasetAudioUuid}`,
    GET_DATASET_STAT_FOR_SCORING: (examUuid) =>
      `${HOST}/api/dataset-stat/${examUuid}`,
    //api/ai-score/:examAudioUuid
    COMPUTE_AI_SCORE: (examAudioUuid) =>
      `${HOST}/api/ai-score/${examAudioUuid}`,
    DELETE_EXAM: `${HOST}/api/delete-exam`,
    LIST_OF_TEACHERS: (schoolUuid) => `${HOST}/api/get-teacher/${schoolUuid}`,
    LIST_OF_STUDENTS: (schoolUuid) => `${HOST}/api/get-students/${schoolUuid}`,
    ASSSIGNMENT: {
      GET: (teacherUuid) => `${HOST}/api/get-teacher-students/${teacherUuid}`,
      ADD: `${HOST}/api/student-teacher/assign`,
      REMOVE: `${HOST}/api/student-teacher/remove`,
    },    
    CREATE_REGULAR_SCHOOL: `${HOST}/api/school`,
    CREATE_TEACHER: `${HOST}/api/auth/signup`,
    SCHOOLS_LISTING: `${HOST}/api/school`,
    GET_EXAM: `${HOST}/api/get-exam`,
    POST_EXAM: `${HOST}/api/post-exam`,
    DOWNLOAD_S3_FILE: `${HOST}/api/download-aws-audio`,
  },
};

export const SECTION_TYPES = {
  LISTENING_NARRATION_AND_QUESTIONS: "Listening Narration & Questions",
  LISTENING_QUESTIONS: "Listening Questions only",
  READING: "Reading Page"
}

export const SectionLists = [
  {
    instruction_audio: "NP_real_Section1_listen.mp3",
    value: "Listening Comprehension",
    welcomeimg: "welcome/p1.png",
    id: 1,
    order:1,
    display:"listening",
    url: "/listeningcomprehension",
    next_url: "/lettersoundidentification",
    next_section_id: 7,
    page_type: SECTION_TYPES.LISTENING_NARRATION_AND_QUESTIONS,
    default: true,
  },
  {
    instruction_audio: "NP_real_Section2_letter.mp3",
    value: "Letter Sound Identification",
    welcomeimg: "welcome/p2.png",
    id: 2,
    order:3,
    display:"letter",
    next_section_id: 3,
    url: "/lettersoundidentification",
    next_url: "/nonwordreading",
    timer: { duration: 60 }
  },
  {

    instruction_audio: "NP_real_Section3_nonword.mp3",
    value: "Non-word Reading",
    welcomeimg: "welcome/p3.png",
    id: 3,
    order:4,
    display:"non-word",
    next_section_id: 4,
    url: "/nonwordreading",
    next_url: "/familiarwordreading",
    timer: { duration: 60 },
  },
  {
    instruction_audio: "NP_real_Section 4_famword.mp3",
    value: "Familiar Word Reading",
    welcomeimg: "welcome/p4.png",
    id: 4,
    order:5,
    display:"familiar",
    next_section_id: 5,
    url: "/familiarwordreading",
    next_url: "/read-aloud",
    timer: { duration: 60 }
  },
  {
    instruction_audio: "NP_real_Section5a_fluency.mp3",
    value: "Read Aloud",
    welcomeimg: "welcome/p5.png",
    id: 5,
    order:6,
    display:"read",
    next_section_id: 6,
    url: "/read-aloud",
    next_url: "/understanding",
    timer: { duration: 60 },
  },
  {
    instruction_audio: "NP_real_Section5b_comp.mp3",
    value: "Understanding",
    welcomeimg: "welcome/p5.png",
    id: 6,
    order:7,
    display:"understanding",
    next_section_id: null,
    url: "/understanding",
    page_type: SECTION_TYPES.LISTENING_QUESTIONS
  },
  {
    value: "Phonemic Awareness",   
    id: 7,
    order:2,
    display:"onset",
    next_section_id: 2,
    page_type: SECTION_TYPES.LISTENING_QUESTIONS
  }
];

export const View = {
  NoAudioPermission: "NoAudioPermission",
  LoginScreen: "LoginScreen",
  CompetitionLoginScreen: "CompetitionLoginScreen",
  LandingScreen: "LandingScreen",
  LottiePlaying: "LottiePlaying",
  LottiedEnded: "LottiedEnded",
  IntroView: "IntroView",
  IntroEndedView: "IntroEndedView",
  AppView: "AppView",
  PostingInProgress: "PostingInProgress",
  SectionSummary: "SectionSummary",
}