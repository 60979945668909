import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Services } from "../../../../services";
const StudentLists = ({ selectedTeacher, school }) => {
  const [studentList,setStudentList] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [checkAll, setCheckAll] = useState(false)
  const [message,setMessage] = useState()
  const UpdateAssignment = (students)=>{
    if (selectedTeacher) {
      Services.Exam.getListOfTeacherStudentAssignment(selectedTeacher).then(
        (result) => {
          let assignments = [];
          students.forEach((item) => {
            const match = result.find((x) => {
              return x.userUuid === item.userUuid;
            });
            if (match) assignments.push(match);
          });
          setSelectedStudents(assignments);
        }
      );
    }
  }
  useEffect(() => {
    Services.Exam.getListOfStudents(school.schoolUuid).then((students) => {
      setStudentList(students);
      setCheckAll(false);
      UpdateAssignment(students)
    });
  }, [selectedTeacher, school.schoolUuid]);

  const handleSubmit = async (e) => {
    const assignment = {
      add: {
        teacherUuid: selectedTeacher,
        studentUuid: selectedStudents.map((x) => {
          return x.userUuid;
        }),
      },
      remove: {
        teacherUuid: selectedTeacher,
        studentUuid: studentList
          .filter((x) => {
            return !selectedStudents.find((y) => {
              return y.userUuid === x.userUuid;
            })?.userUuid;
          })
          .map((x) => {
            return x.userUuid;
          }),
      },
    };

    e.preventDefault();

    const response = Services.Exam.SaveTeacherStudentAssignment(assignment);
    response.then((result) => {
      setCheckAll(false)
      setMessage('Assignment Saved.')
      UpdateAssignment(studentList)
      setTimeout(() => {
        setMessage(null)
      }, 2000);
    });
  };
  return (
    <div>
      {message && <span>{message}</span>}
      <Form style={{}} onSubmit={handleSubmit} id="form">
        <table
          className="table table-bordered"
          style={{ marginLeft: "2em", verticalAlign: "middle" }}
        >
          <tfoot style={{ textAlign: "right" }}>
            <tr>
              <th colSpan={5}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ color: "white" }}
                >
                  Save Assignment
                </button>
              </th>
            </tr>
          </tfoot>
          <thead>
            <tr>
              <th colSpan={5}>{"Students"}</th>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={checkAll}
                  onChange={(e) => {
                    setCheckAll(e.target.checked);
                    if (!e.target.checked) setSelectedStudents([]);
                    else setSelectedStudents(studentList);
                  }}
                />
              </td>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Student's Name</th>
              <th scope="col">Email</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "left" }}>
            {studentList.map((item, index) => {
              // {userUuid, username, name, schoolUuid, age, gender, class, ethnicity,
              //pin, resetToken, tokenExpire, email, accountType, createdAt, updatedAt, School, UserRole}
              return (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      value={item.userUuid}
                      id={item.userUuid}
                      name={item.userUuid}
                      checked={
                        selectedStudents.find((x) => {
                          return x.userUuid === item.userUuid;
                        })
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        const checkedId = event.target.value;
                        if (event.target.checked) {
                          const match = studentList.find((x) => {
                            return x.userUuid === checkedId;
                          });
                          const list = selectedStudents.map((x) => {
                            return x;
                          });
                          list.push(match);
                          setSelectedStudents(list);
                        } else {
                          const list = selectedStudents.filter((x) => {
                            return x.userUuid !== checkedId;
                          });
                          setSelectedStudents(list);
                        }
                      }}
                    />
                    {/* <Form.Check
                      inline
                      name="assign"
                      id="assign"
                      type={"checkbox"}                      
                      defaultChecked={true}
                      style={{ paddingTop: "0.5em", marginRight: "unset" }}
                    /> */}
                  </td>
                  <th scope="row" style={{ textAlign: "center" }}>
                    {index + 1}
                  </th>
                  <td>{item.username}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Form>
    </div>
  );
};

export default StudentLists;
