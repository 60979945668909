import React, { useEffect, useState } from "react";
import { Services } from "../../../services";
import { Link } from "react-router-dom";
import TeacherForm from "./TeacherForm";
import NewStudentForm from "./Student/NewStudentForm";
import StudentLists from "./Student/StudentLists";

const TeacherListing = ({ setView, Views, school }) => {
  const views = {
    TeacherForm: "TeacherForm",
    StudentForm: "StudentForm",
    TeacherListing: "TeacherListing",
  };
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState();

  const [teacherView, setTeacherView] = useState(views.TeacherListing);
  const getTeachersAndStudents = (school) => {
    Services.Exam.getListOfTeachers(school?.schoolUuid).then((data) => {
      let list = data;
      if (Services.User.isTeacher() === true)
        list = data.filter((x) => {
          return x.userUuid === Services.User.getLocalStorageDetails().userUuid;
        });

      setTeachers(list);
      if (list.length > 0 && !selectedTeacher) {
        setSelectedTeacher(list[0]?.userUuid);
      }
    });
  };
  useEffect(() => {
    getTeachersAndStudents(school);
  }, [school]);

  const TeacherLists = () => {
    return (
      <table
        className="table table-bordered"
        style={{ verticalAlign: "middle" }}
      >
        <thead>
          <tr>
            <th colSpan={5}>{"Teachers"}</th>
          </tr>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Username</th>
            <th scope="col">Teacher's Name</th>
            <th scope="col">Email</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          {teachers.map((item, index) => {
            // {userUuid, username, name, schoolUuid, age, gender, class, ethnicity,
            //pin, resetToken, tokenExpire, email, accountType, createdAt, updatedAt, School, UserRole}
            return (
              <tr key={index}>
                <th scope="row" style={{ textAlign: "center" }}>
                  {index + 1}
                </th>
                <td>{item.username}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>
                  <input
                    type="radio"
                    name="selectedTeacher"
                    value={item.userUuid}
                    checked={item.userUuid === selectedTeacher ? true : false}
                    onChange={(e) => {
                      setSelectedTeacher(e.currentTarget.value);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const BackButton = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ flex: "auto", alignSelf: "flex-start", textAlign: "left" }}
          >
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => {
                setView(Views.ListOfSchool);
              }}
            >
              Back
            </Link>
          </div>
        </div>
      </>
    );
  };
  const NavBar = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <h3>{school?.name}</h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: "2em",
          }}
        >
          <div
            style={{
              flex: "auto",
              alignSelf: "flex-end",
              textAlign: "right",
            }}
          >
            {Services.User.isModerator() && (
              <Link
                style={{ textDecoration: "none", paddingRight: "2em" }}
                onClick={(e) => {
                  setTeacherView(views.TeacherForm);
                }}
              >
                New Teacher
              </Link>
            )}
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => {
                setTeacherView(views.StudentForm);
              }}
            >
              New Student
            </Link>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <BackButton />
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        {views.TeacherForm === teacherView && (
          <TeacherForm
            getTeachersAndStudents={getTeachersAndStudents}
            setTeacherView={setTeacherView}
            views={views}
            school={school}
          />
        )}
        {views.StudentForm === teacherView && (
          <NewStudentForm
            getTeachersAndStudents={getTeachersAndStudents}
            setTeacherView={setTeacherView}
            views={views}
            school={school}
          />
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1", flexDirection: "row" }}>
          {<TeacherLists />}
        </div>
        <div style={{ flex: "1", flexDirection: "row" }}>
          {<StudentLists selectedTeacher={selectedTeacher} school={school} />}
        </div>
      </div>
    </div>
  );
};

export default TeacherListing;
