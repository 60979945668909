import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Services } from '../../services';
import { useNavigate } from 'react-router-dom';

const RegularSchoolForm = ({setView, Views,getSchools }) => {
  const [btnSubmitStatus,setBtnSubmitStatus] = useState(false)
  const [message,setMessage] = useState()
  const nav = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnSubmitStatus(false)
    const form = document.getElementById("form");
    const data = new FormData(form);
    const payload = {
      name: data.get("name"),
      language: data.get("language"),
    };
    let msg = "school created.";
    const result = await Services.School.createRegularSchool(payload)===true
    if(result===false) msg = "error creating school"
    setMessage(msg)
    setTimeout(()=>{
      if(result===true) {
        getSchools()
        setView(Views.ListOfSchool)
      }
    }, 1000)
  };
  
  const [formData, setFormData] = useState({
    name: "",
    language: ""
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  return (
    <div style={{ width: "75%" }}>
      <h3 style={{ height: "2em" }}>New School Registration</h3>
      <div style={{ height: "2em" }}>{message}</div>
      <Form onSubmit={handleSubmit} id="form">
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSchool">
          <Form.Label column sm={5} style={{ textAlign: "right" }}>
            School
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              type="text"
              placeholder=""
              onChange={handleChange}
              name="name"
              defaultValue={formData.name}
              required={true}
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="formHorizontalNoOfStudents"
        >
          <Form.Label
            column sm={5}
            style={{ textAlign: "right", display: "inline-block" }}
          >
            Language Code
          </Form.Label>
          <Col sm={3}>
            <Form.Select
              name="language"
              required
              onChange={handleChange}
              defaultValue={formData.language}
            >
              <option value="EN">EN</option>
              <option value="EA">EA</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
        <Col sm={5}></Col>
          <Col 
          style={{textAlign:'left'}}
          >
            <button
              type="submit"
              className="btn btn-primary"
              disabled={btnSubmitStatus}
            >
              {"Create School"}
            </button>
            &nbsp;
            <button
              onClick={() => {
                setView(Views.ListOfSchool);
              }}
              className="btn btn-primary"
              type="button"
              style={{ color: "white" }}
            >
              Cancel
            </button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
}

export default RegularSchoolForm
