import React from 'react';
import PropTypes from 'prop-types';
import styles from './StudentInfo.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ScoreStudentInfo = ({ show, handleClose, student }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Student Info</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{student?.name}</td>
          </tr>
          <tr>
            <td>School</td>
            <td>{student?.schoolName}</td>
          </tr>
          <tr>
            <td>Age</td>
            <td>{student?.age}</td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>{student?.gender}</td>
          </tr>
          <tr>
            <td>Class</td>
            <td>{student?.class}</td>
          </tr>
          <tr>
            <td>Ethnicity</td>
            <td>{student?.ethnicity}</td>
          </tr>
        </tbody>
      </table>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

ScoreStudentInfo.propTypes = {};

ScoreStudentInfo.defaultProps = {};

export default ScoreStudentInfo;
