import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Services } from "../../../services";
const TeacherForm = ({ setTeacherView, views, school, getTeachersAndStudents }) => {
  const [message, setMessage] = useState();
  const [btnSubmitStatus, setBtnSubmitStatus] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnSubmitStatus(false);
    const form = document.getElementById("form");
    const data = new FormData(form);

    const payload = {
      name: data.get("name"),
      username: data.get("username"),
      password: data.get("password"),
      schoolUuid: school?.schoolUuid,
      roles: ["teacher"],
    };
    const msg = {
      success: "Teacher created.",
      failure: "error creating teacher",
    };

    const result = await Services.School.createTeacher(payload);
    let status = msg.success;
    if (result?.status === false)
      status = result?.message ? result?.message : msg.failure;
    setMessage(status);
    setTimeout(() => {
      if (result?.status === true) {
        setTeacherView(views.TeacherListing);
        getTeachersAndStudents(school);
      }
    }, 1000);
  };

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    // name: "Demo Teacher",
    // username: "demoteacher",
    // password: "123456",
  });
  const handleChange = (event) => {
    //   const { name, value } = event.target;
    //   setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const TeacherRegistration = () => {
    return (
      <div style={{ width: "75%" }}>
        <Form style={{}} onSubmit={handleSubmit} id="form">
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={5}></Form.Label>
            <Col sm={7} style={{ textAlign: "left" }}>
              {message}
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Teacher's Name
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                type="text"
                placeholder=""
                onChange={handleChange}
                name="name"
                defaultValue={formData.name}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Username
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="username"
                defaultValue={formData.username}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Password
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="password"
                defaultValue={formData.password}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 15, offset: 3 }}>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={btnSubmitStatus}
              >
                {"Create Teacher"}
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setTeacherView(views.TeacherListing);
                }}
                className="btn btn-primary"
                type="button"
                style={{ color: "white" }}
              >
                Cancel
              </button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  };
  return <TeacherRegistration />;
};

export default TeacherForm;
