import { URLS } from "../constants/config";

class SchoolService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  async listSchool() {
    try {
      const url = URLS.INTERNAL.SCHOOLS_LISTING;
      return await this.httpClient.get(url);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async createRegularSchool(payload) {
    try {
      const url = URLS.INTERNAL.CREATE_REGULAR_SCHOOL;
      const response = await this.httpClient.post(url, payload);
      if (response?.ok) return true;
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async createTeacher(payload) {
    try {
      const url = URLS.INTERNAL.CREATE_TEACHER;
      const response = await this.httpClient.post(url, payload);
      if (response?.ok) return {status:true};
      return {message:(await response.json())?.message, status:false};
    } catch (error) {
      return {message:error, status:false};
    }
  }
  async createStudent(payload) {
    const result = {status:false,message:null}
    try {
      const url = URLS.INTERNAL.REGISTER;
      const response = await this.httpClient.post(url, payload);
      if (response?.ok) result.status = true;
      else result.message = (await response.json())?.message
      return result;
    } catch (error) {
      result.message = error
      return result;
    }
  }
  sortASC(data) {
    return data.sort((p1, p2) =>
      p1.name > p2.name ? 1 : p1.name < p2.name ? -1 : 0
    );
  }
}
export default SchoolService;