import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Services } from "../../../../services";

const NewStudentForm = ({ setTeacherView, views, school, getTeachersAndStudents }) => {  
  const [message, setMessage] = useState('');
  const [btnSubmitStatus, setBtnSubmitStatus] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnSubmitStatus(false);
    const form = document.getElementById("form");
    const data = new FormData(form);

    const payload = {
      name: data.get("name"),
      username: data.get("username"),
      password: data.get("password"),
      schoolUuid: school?.schoolUuid,
      class:data.get('class'),
      ethnicity:data.get('ethnicity'),
      age:data.get('age'),
      gender:data.get('gender'),
    };
    const msg = {
      success: "Student created.",
      failure: "error creating student",
    };

    const result = await Services.School.createStudent(payload);
    let status = msg.success;
    if (result.status === false)
      status = result?.message ? result?.message : msg.failure;
    setMessage(status);
    setTimeout(() => {
      if (result.status === true) {
        setTeacherView(views.TeacherListing);
        getTeachersAndStudents(school);
      }
    }, 1000);
  };

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    class:'',
    ethnicity:'',
    age:'',
    gender:'male'
  });
  const handleChange = (event) => {
    //   const { name, value } = event.target;
    //   setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    //   event.target.focus()
  };

  const Registration = () => {
    return (
      <>
        <Form style={{}} onSubmit={handleSubmit} id="form">
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={5}></Form.Label>
            <Col sm={7} style={{ textAlign: "left" }}>
              {message}
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Student's Name
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                type="text"
                placeholder=""
                onChange={handleChange}
                name="name"
                defaultValue={formData.name}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Username
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="username"
                defaultValue={formData.username}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Password
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="password"
                defaultValue={formData.password}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Class
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="class"
                defaultValue={formData.class}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Ethnicity
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="ethnicity"
                defaultValue={formData.ethnicity}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Age
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="text"
                placeholder=""
                name="age"
                defaultValue={formData.age}
                onChange={handleChange}
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalNoOfStudents"
          >
            <Form.Label column sm={5} style={{ textAlign: "right" }}>
              Gender
            </Form.Label>
            <Col sm={7}>
              <Form.Check
                inline
                name="gender"
                label="Male"
                value={"male"}
                type={"radio"}
                required={true}
                defaultChecked={true}
                style={{ paddingTop: "0.5em" }}
              />
              <Form.Check
                inline
                name="gender"
                label="Female"
                value={"female"}
                type={"radio"}
                required={true}
                style={{ paddingTop: "0.5em" }}
              />             
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 15, offset: 3 }}>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={btnSubmitStatus}
              >
                {"Create Student"}
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setTeacherView(views.TeacherListing);
                }}
                className="btn btn-primary"
                type="button"
                style={{ color: "white" }}
              >
                Cancel
              </button>
            </Col>
          </Form.Group>
        </Form>
      </>
    );
  };
  return <Registration />;
};

export default NewStudentForm;
