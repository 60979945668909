import ModerateSchool from "../components/Moderate/School";
import React from "react";
import NavBar from "../components/NavBar/NavBar";
import { useLocation } from "react-router-dom";
import { Services } from "../services";


export const Moderate = () => {
  const location = useLocation();
  const param = new URLSearchParams(location.search).get("form");
  
  return (
    <>
      <NavBar />
      <div className="App-container">
        <div className="App-page-title">
          {Services.User.isTeacher()? "Roaster":null}
          {Services.User.isModerator()? "Moderate":null}          
        </div>
        <ModerateSchool />
      </div>
    </>
  );
};
